
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import Person from "@material-ui/icons/Person";
import AssignmentIcon from '@material-ui/icons/Assignment';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import ComputerIcon from "@material-ui/icons/Computer";
import QueueIcon from "@material-ui/icons/Queue";



export default [
  {
    pages: [
      {
        title: 'Dashboard',
        href: '/dashboard',
        icon: DashboardIcon,
      },
      {
        title: 'Doctor List',
        href: '/doctorList',
        icon: QueueIcon,
      },
      {
        title: 'Notification',
        href: '/notification',
        icon: NotificationImportantIcon,
      },
      {
        title: 'Profile',
        href: '/profile',
        icon: AccountCircleIcon,
      },
      {
        title: 'Old Patient Records',
        href: '/patient_list',
        icon: Person,
      },
      {
        title: "Global Flowboard",
        href: "/global_flowboard",
        icon: ComputerIcon,
      },
      {
        title: 'Patient Report',
        href: '/patient_report',
        icon: AssignmentIcon,
      }
    ]
  }
];
