import React, { Fragment, useEffect, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Drawer, Divider, Typography, Button, Grid } from "@material-ui/core";

import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";

import AppBar from "@material-ui/core/AppBar";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MenuIcon from "@material-ui/icons/Menu";
import useRouter from "utils/useRouter";
import { Navigation } from "components";
import navigationConfig from "./navigationConfig";
import { withRouter } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import axios from "../../../../utils/axios1";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { ActionCableConsumer } from "react-actioncable-provider";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import NotificationIndicator from "./NotificationIndicator";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import "../../../../components/toast.css";
import Tooltip from "@material-ui/core/Tooltip";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    // overflowY: 'auto'
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - 189px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    flexShrink: 0,
    width: "145px",
  },
  patientWaiting: {
    [theme.breakpoints.down("sm")]: {
      width: "85%",
    },
    [theme.breakpoints.up("sm")]: {},
  },
  drawerPaper: {
    // width: drawerWidth,
    width: 192,
    backgroundColor: "#3cb0b3",
    overflowY: "hidden",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,

    padding: "11px",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  navigation: {
    // marginTop: theme.spacing(2),
    marginTop: "20px",
    paddingLeft: "0",
    paddingTop: "0",
    paddingBottom: "0",
    marginBottom: "0",
    listStyle: "none",
    position: "unset",
  },
}));
const drawerWidth = 240;
const NavBar = (props) => {
  const { openMobile, onMobileClose, className } = props;

  const classes = useStyles();
  const router = useRouter();
  // const session = useSelector(state => state.session);

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = useState("");
  const [patientWaitingCount, setPatientWaitingCount] = useState();
  const [showPatientWaitingCount, setShowPatientWaitingCount] = useState();

  const [openSnack, setOpenSnack] = useState(true);
  const [severity, setSeverity] = useState("info");

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    let PatientCount = localStorage.getItem("patientCount");
    setShowPatientWaitingCount(PatientCount);
  }, [patientWaitingCount]);

  useEffect(() => {
    if (props.history.location.pathname === "/dashboard") {
      document.getElementById("backIcon").disabled = true;
      document.getElementById("backIcon").style.color = "lightblue";
      console.log();
    } else {
      document.getElementById("backIcon").disabled = false;
      document.getElementById("backIcon").style.color = "white";
    }
  }, [props.history.location.pathname]);

  useEffect(() => {
    if (openMobile) {
      onMobileClose && onMobileClose();
    }
  }, [onMobileClose, openMobile, router.location.pathname]);

  const handleLogOut = () => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      let id = localStorage.getItem("user");
      axios
        .get(`/logout/${id} `, { headers: { Authorization: token } })
        .then((response) => {
          toast(<p>{response.data.message}</p>, {
            className: "custom",
            autoClose: 3000,
          });
          if (response !== null || response !== "" || response !== undefined) {
            localStorage.clear();
          }
          setTimeout(() => {
            props.history.push("/auth/login");
          }, 2000);
        })
        .catch((error) => {
          if (error.response.data !== "") {
            toast.error(<p>{error.response.data.error}</p>, {
              autoClose: 3000,
            });
            // eslint-disable-next-line no-cond-assign
            if ((error.response.status = "401")) {
              toast.error(<p>{error.response.data.error}</p>, {
                autoClose: 3000,
              });
              localStorage.clear();
              props.history.push("/auth/login");
            }
          } else {
            alert(error.response.statusText);
          }
        });
    }
  };

  const navbarContent = (
    <div
      style={{ marginLeft: "1px", padding: "0px" }}
      className={classes.content}
    >
      <nav className={classes.navigation}>
        {navigationConfig.map((list) => (
          <Navigation
            style={{ fontSize: "12px" }}
            component="div"
            key={list.pages}
            pages={list.pages}
            title={list.title}
          />
        ))}
      </nav>
    </div>
  );

  const handleReceived = (message) => {
    console.log(message);
    console.log("Recieved");
    try {
      if (message) {
        setMessage(message.data);
        localStorage.setItem("patientCount", message.patient_count);
        setPatientWaitingCount(message.patient_count);
        setOpenSnack(true);
      } else {
        setMessage("res not OK");
        setOpenSnack(true);
        setSeverity("warning");
      }
    } catch (err) {
      setMessage("Error from catch");
      setOpenSnack(true);
      setSeverity("error");
    }
  };

  const intialize = () => {
    // console.log("Initialized");
  };
  const rejected = () => {
    // console.log("Rejected");
  };
  const disconnected = () => {
    // console.log("Disconnected");
  };

  const connected = () => {
    // console.log("Connected");
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
    setMessage("");
  };

  const handleBackButton = () => {
    // let token = localStorage.getItem("jwt");
    // if(token){
    if (props.history.location.pathname === "/dashboard") {
      console.log();
    } else {
      props.history.goBack();
    }
  };

  return (
    <Fragment>
      {message ? (
        <Snackbar
          open={openSnack}
          style={{ top: "10%" }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          //  autoHideDuration={50000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={severity}
            style={{ backgroundColor: "#af4c95" }}
          >
            {message}
          </Alert>
        </Snackbar>
      ) : null}
      <div className={classes.root}>
        <AppBar
          style={{ backgroundColor: "#3cb0b3" }}
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar>
            <ActionCableConsumer
              channel="NotificationChannel"
              onReceived={handleReceived}
              onInitialized={intialize}
              onRejected={rejected}
              onDisconnected={disconnected}
              onConnected={connected}
            ></ActionCableConsumer>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>

            <Typography variant="h6" noWrap></Typography>
            <Grid item xs={2} sm={2} md={2}>
              <div>
                <ArrowBackIcon id="backIcon" onClick={handleBackButton} />
              </div>
            </Grid>
            <Grid item xs={4} sm={4} md={8} container justify="flex-start">
              <div
                style={{
                  textAlign: "center",
                  borderRadius: "6%",
                }}
                className={classes.patientWaiting}
              >
                <Typography>
                  <label
                    style={{ fontSize: 16, color: "white", fontWeight: 500 }}
                  >
                    Patient Waiting:{" "}
                    <span
                      style={{ fontSize: 16, color: "white", fontWeight: 500 }}
                    >
                      {showPatientWaitingCount}
                    </span>
                  </label>
                </Typography>
              </div>
            </Grid>
            <Grid
              item
              xs={3}
              sm={3}
              md={1}
              container
              justify="flex-end"
              style={{ flexGrow: 1 }}
            >
              <NotificationIndicator />
            </Grid>
            <Grid item xs={3} sm={3} md={2} container justify="flex-end">
              <div style={{ cursor: "pointer" }}>
                <Tooltip title="Log Out" aria-label="Log Out">
                  <ExitToAppIcon onClick={handleLogOut} />
                </Tooltip>
              </div>
            </Grid>
            <ToastContainer />
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </div>
          <Divider />

          <div
            style={{ minWidth: 0, width: 0 }}
            // {...rest}
            className={clsx(classes.root, className)}
          >
            {navbarContent}
          </div>
          <Divider />
        </Drawer>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          <div className={classes.drawerHeader} />
        </main>
      </div>
    </Fragment>
  );
};

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default withRouter(NavBar);
