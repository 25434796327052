import React, { useState, Fragment, useRef } from "react";
import {
  Button,
  Badge,
  Popper,
  Paper,
  Grow,
  ClickAwayListener,
  MenuItem,
  MenuList,
} from "@material-ui/core";
import NotificationsIcon from "@material-ui/icons/Notifications";

const notificationOptions = [
  "Another Notification from nurse",
  "Another Notification from admin",
];

const NotificationIndicator = ({ modeTextColor }) => {
  const [showNotification, setShowNotification] = useState(false);
  const notificationRef = useRef(null);
  const [notifications, setNotifications] = useState(notificationOptions);

  const handleClose = (event) => {
    if (
      notificationRef.current &&
      notificationRef.current.contains(event.target)
    ) {
      return;
    }
    setShowNotification(false);
  };

  const handleToggle = () => {
    setShowNotification((prevOpen) => !prevOpen);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setShowNotification(false);
    }
  }

  const createNotificationItems = (notifications) => {
    return notifications.map((notification, index) => {
      let content = "";
      content = notification.trim();

      return (
        <MenuItem key={index} onClick={handleClose}>
          {content}
        </MenuItem>
      );
    });
  };

  return (
    <Fragment>
      <Button
        ref={notificationRef}
        aria-controls={showNotification ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Badge color="error" badgeContent={notifications.length} max={20}>
          <NotificationsIcon style={modeTextColor} />
        </Badge>
      </Button>

      <Popper
        open={showNotification}
        anchorEl={notificationRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={showNotification}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  {createNotificationItems(notifications)}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
};

export default NotificationIndicator;
