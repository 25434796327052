
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import DashboardLayout from './layouts/Dashboard';
import Dashboard from './views/Dashboard';
// import DoctorCategory from './views/DoctorCategory/DoctorCategory';

const routes = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/auth/login" />
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('views/Login'))
      },
      {
        path: "/auth/forgot_password",
        exact: true,
        component: lazy(() => import("views/Login/ForgotPassword")),
      },
      {
        path: "/auth/password_resets/:id",
        exact: true,
        component: lazy(() => import("views/Login/ResetPassword")),
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('views/Error404'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    route: '*',
    component: DashboardLayout,
    routes: [
      {
        path: '/dashboard',
        exact: true,
        component: Dashboard
      },
      {
        path: '/dashboard/:id/:type/is_sent_to_pool/:poolid',
        exact: true,
        component: lazy(() => import('./views/DashboardLiveList'))
      },
      {
        path: '/patient_list',
        exact: true,
        component: lazy(() => import('./views/PatientList/PatientList2'))
      },
      {
        path: '/patient_list/:id/:type/is_sent_to_pool/:poolid',
        exact: true,
        component: lazy(() => import('./views/PatientListDetail/PatientListDetail'))
      },
      {
        path: '/doctorList',
        exact: true,
        component: lazy(() => import('./views/OnlineDoctors/OnlineDoctors'))
      },
      {
        path: '/notification',
        exact: true,
        component: lazy(() => import('./views/Notification'))
      },
      {
        path: '/notification/:id',
        exact: true,
        component: lazy(() => import('./views/Notification'))
      },
      {
        path: "/global_flowboard",
        exact: true,
        component: lazy(() =>
          import("./views/Globalflowboard/Globalflowboard")
        ),
      },
      {
        path: '/patient_report',
        exact: true,
        component: lazy(() => import('./views/PatientReport/PatientReport'))
      },
      {
        path: '/profile',
        exact: true,
        component: lazy(() => import('./views/UserProfile/UserProfileTab'))
      },
     
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  }
];

export default routes;
