import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TablePagination, Link } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

import SearchBar from "../../components/SearchBar/SearchBar";
import Loader from "../../components/Spinner/Spinner";
import NoData from "../../assets/img/nodata.png";
import PatientCard from "./PatientCard/PatientCard";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { getRequest } from "../../api/Api";
import { encodeGetParams } from "../../commonFunctions/Common";
import { getQueryRequest } from "../../api/QueryRequest";

const styles = (theme) => ({
  root: {
    padding: "16px",
  },
  results: {
    marginTop: 30,
  },
  paginate: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  },
  fab: {
    backgroundColor: "#ef4281",
    float: "right",
    marginRight: 15,
  },
});
const useStyles = makeStyles(styles);

const Dashboard = () => {
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const [filteredPatients, setFilteredPatients] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [patientRecord, setPatientRecord] = useState([]);
  const [count, setCount] = useState(0);
  const [totalCount, setTotalCount] = useState();

  const handleChangePage = (event, page) => {
    setPage(page);
    let params = {};
    params.page = page + 1;
    params.items = rowsPerPage;

    let queryString = encodeGetParams(params);
    getQueryRequest("get_nurse_today_visits", queryString).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setPatientRecord(data.data.data);
      }
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    let params = {};
    params.page = page + 1;
    params.items = event.target.value;
    let queryString = encodeGetParams(params);
    getQueryRequest("get_nurse_today_visits", queryString).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setPatientRecord(data.data.data);
      }
    });
  };

  const fetchNurseTodayVisits = () => {
    const token = "Bearer " + localStorage.getItem("jwt");
    getRequest("get_nurse_today_visits", token).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setPatientRecord(data.data.data);
        setTotalCount(data.total_rows);
        setLoading(false);
      }
    });
  };

  const PullNewRecord = () => {
    const token = "Bearer " + localStorage.getItem("jwt");
    getRequest("assign_visit_to_nurse", token).then((data) => {
      if (data.error) {
        toast.error(<p>{data.error}</p>, { autoClose: 3000 });
      } else {
        setCount(count + 1);
      }
    });
  };

  useEffect(() => {
    setLoading(true);
    fetchNurseTodayVisits();
  }, [count]);

  useEffect(() => {
    if (patientRecord.length !== 0) {
      setFilteredPatients(
        patientRecord.filter((patient) => {
          return patient.attributes.patient.name
            .toLowerCase()
            .includes(search.toLowerCase());
        })
      );
    }
  }, [search, patientRecord]);

  return (
    <div className={classes.root} style={{ marginTop: "13px" }}>
      {isLoading ? (
        <Loader />
      ) : (
        <Grid>
          <Grid item xs={12} sm={12} md={12}>
            <SearchBar onSearch={(e) => setSearch(e.target.value)} />

            <Link color="inherit" variant="h6" onClick={PullNewRecord}>
              <Tooltip title="Pull New Record" aria-label="Pull New Record">
                <Fab className={classes.fab}>
                  <CloudDownloadIcon />
                </Fab>
              </Tooltip>
            </Link>
          </Grid>
          <br />
          <br />
          <Grid item xs={12} sm={12} md={12}>
            <div className={classes.results}>
              {filteredPatients.length === 0 && (
                <Grid sx={12} sm={12} md={12} item container justify="center">
                  <div>
                    <img src={NoData} style={{ width: 300 }} alt="nodata" />
                  </div>
                </Grid>
              )}

              {filteredPatients.map((project) => (
                <PatientCard key={project.id} project={project} />
              ))}
            </div>
            <div className={classes.paginate}>
              <TablePagination
                component="div"
                count={parseInt(totalCount)}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
              />
            </div>
          </Grid>
          <ToastContainer />
        </Grid>
      )}
    </div>
  );
};

export default Dashboard;
