
import React from 'react';
import './Spinner.css';



const Loader=()=> {
 

  return (
    <div className="loader">Loading...</div>
  );
  }

export default Loader;
