import React from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Avatar, Card, CardContent, Link, Typography,colors, } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import moment from "moment";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  overrides: {
    MuiAvatar: {
      img: {
        objectFit: 'fill',
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    marginBottom: theme.spacing(2),
    boxShadow:
      "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(76, 175, 80,.4)",
  },
  content: {
    padding: theme.spacing(2),
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      flexWrap: "wrap",
    },
    "&:last-child": {
      paddingBottom: theme.spacing(2),
    },
  },
  header: {
    maxWidth: "100%",
    width: 240,
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2),
      flexBasis: "100%",
    },
  },
  avatar: {
    marginRight: theme.spacing(2),
    width: "85px",
    height: "85px",
  },
  stats: {
    padding: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      flexBasis: "50%",
    },
  },
  fab: {
    margin: 2,
    backgroundColor: "#378f30",
    width: 42,
    height: 42,
  },
  actions: {
    padding: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      flexBasis: "50%",
    },
  },
}));

const ProjectCard = (props) => {
  const { project, className, ...rest } = props;
  console.log(project.attributes.is_sent_to_patient_pool);
  var today = new Date();
  var birthDate = new Date(project.attributes.patient.dob);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  const classes = useStyles();
  const statusColors = {
    "Details being recorded": colors.orange[600],
    "Submitted and waiting for review": colors.orange[600],
    "Waiting for doctor": colors.orange[600],
    "Doctor review": colors.orange[600],
    Cancelled: colors.red[600],
    "Patient stepped out": colors.red[600],
    "Checked out": colors.green[600],
  };
  return (
    <React.Fragment>
      {project.attributes.is_sent_to_patient_pool === 0 ? (
        <Card {...rest} className={clsx(classes.root, className)}>
          <CardContent className={classes.content}>
            <div className={classes.header}>
            <ThemeProvider theme={theme}>
              <Avatar
                alt="Author"
                className={classes.avatar}
                src={project.attributes.patient.profile_photo}
              ></Avatar>
               </ThemeProvider>
              <div>
                <Typography style={{ fontWeight: 500 }} variant="body2">
                  Name
                </Typography>
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  noWrap
                  to="#"
                  variant="h5"
                  style={{ fontSize: "unset", fontWeight: 300 }}
                >
                  {project.attributes.patient.name}
                </Link>
              </div>
            </div>
            <div className={classes.stats}>
              <Typography style={{ fontWeight: 500 }} variant="body2">
                MSO
              </Typography>
              <Typography
                style={{ fontSize: "unset", fontWeight: 300 }}
                variant="h6"
              >
                {project.attributes.created_by.name}
              </Typography>
            </div>
            <div className={classes.stats}>
              <Typography style={{ fontWeight: 500 }} variant="body2">
                Age
              </Typography>
              <Typography
                style={{ fontSize: "unset", fontWeight: 300 }}
                variant="h6"
              >
                {age}
              </Typography>
            </div>
            <div className={classes.stats}>
              <Typography style={{ fontWeight: 500 }} variant="body2">
                Gender
              </Typography>
              <Typography
                style={{ fontSize: "unset", fontWeight: 300 }}
                variant="h6"
              >
                {project.attributes.patient.gender}
              </Typography>
            </div>
            <div className={classes.stats}>
              <Typography style={{ fontWeight: 500 }} variant="body2">
                Number
              </Typography>
              <Typography
                style={{ fontSize: "unset", fontWeight: 300 }}
                variant="h6"
              >
                {project.attributes.patient.mobile}
              </Typography>
            </div>
            <div className={classes.stats}>
              <Typography style={{ fontWeight: 500 }} variant="body2">
                Registered Date
              </Typography>
              <Typography
                style={{ fontSize: "unset", fontWeight: 300 }}
                variant="h6"
              >
                {moment(project.attributes.patient.created_at).format(
                  "Do MMM YYYY"
                )}
              </Typography>
            </div>
            <div className={classes.stats}>
              <Typography style={{ fontWeight: 500 }} variant="body2">
                Last Visit Date
              </Typography>
              <Typography
                style={{ fontSize: "unset", fontWeight: 300 }}
                variant="h6"
              >
              {moment.utc(project.attributes.visit_datetime)
               .local()
               .format("DD MMM YYYY HH:mm")}
              </Typography>
            </div>
            <div className={classes.stats} style={{ width:'15%' }}>
              <Typography style={{ fontWeight: 500 }} variant="body2">
                Flowboard Status
              </Typography>
              <Typography
                style={{
                  color:
                    statusColors[
                      project.attributes.flow_board?.status
                    ],
                    fontSize: "unset", fontWeight: 300 
                }}
                variant="h6"
              >
              {project.attributes.flow_board?.status}
              </Typography>
            </div>
            <div className={classes.actions}>
              <Typography style={{ fontWeight: 500 }} variant="body2">
                Action
              </Typography>
              {project.attributes.flow_board?.status_key < 3 && project.attributes.status?.key === 1 ?
              <Link
                color="inherit"
                component={RouterLink}
                to={`/dashboard/${project.attributes.patient.id}/${project.attributes.id}/is_sent_to_pool/${project.attributes.is_sent_to_patient_pool}`}
                variant="h6"
              >
                <Tooltip title="View" aria-label="View">
                  <Fab className={classes.fab}>
                    <VisibilityIcon />
                  </Fab>
                </Tooltip>
              </Link>:null}
            </div>
          </CardContent>
        </Card>
      ) : null}
    </React.Fragment>
  );
};

ProjectCard.propTypes = {
  className: PropTypes.string,
  project: PropTypes.object.isRequired,
};

export default ProjectCard;
